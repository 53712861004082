.header {
    position: relative;
    z-index: 2;
    margin-bottom:0;
    
    .header_top {
        background: $navbar-top-bg;
        border-bottom: 1px solid $navbar-top-border;
        position: relative;
        z-index: 1;
        padding: 5px 0px;
        .top-left {
            text-align: left;
        }
        .top-middle {
            text-align: right;
        }
        .top-right {
            text-align: right;
        }
    }
     .main-nav {
          height: 75px;
          margin-top: 0px;
        }
 

        .navbar-header {
            //logo
            .navbar-brand {
                @media(max-width:500px) {
                    width:80%;
                    img {
                        width:100%;
                    }
                        
                }
            }
        }
            //actual menu text <ul>
            #menu-header {
                //float left or right
                float:right;
                @media(max-width:767px) {
                    float:none !important;
                }
                > li {
                    > a {

                        font-size: $nav-font-size;
                        font-family: $nav-font-family;

                        &:hover,
                        &:focus {

                        }      
                    }
                }

            
        }
    }


@media (max-width: $screen-sm) {
    .header {
        
        .header_top {
            border-bottom: none;
            border-bottom: none;
            width: auto;
            
            .top-left, .top-right, .top-middle {
                text-align: center;
            }
            
        }
        
    }
}