// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #445e79;
$nova-font:           "proxima-nova-alt-ext-cond", Arial, sans-serif;
//** Background color for `<body>`.
$body-bg:               #fff;

//** Global text color on `<body>`.
$text-color:            #222;

//** Global textual link color.
$link-color:            $brand-primary;

$mainColors: (
	'white': #ffffff,
	'black': #000000,
	'orange':#ff6633,
	'green': #339933,   
	'blue':  #006699,       
	'gray':  #333333
);

// return color value from given key
@function color($key) {
  @if map-has-key($mainColors, $key) {
    @return map-get($mainColors, $key);
  }
  @warn "Unknown `#{$key}` in $colors.";
  @return null;
}

//== Typography (COPIED FROM BOOTSTRAP VARIABLES)
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:  "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-serif:       Georgia, "Times New Roman", Times, serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family-base:        $font-family-sans-serif;

$font-size-base:          18px;
$font-size-large:         ceil(($font-size-base * 1.25)); // ~18px
$font-size-small:         ceil(($font-size-base * 0.85)); // ~12px

$headings-font-family:    inherit;
$headings-font-weight:    500;
$headings-line-height:    1.1;
$headings-color:          inherit;


//== Navbar 
//
$navbar-height:                    75px;
$navbar-default-bg:                #445e79;
$navbar-default-border:            darken($navbar-default-bg, 6.5%);
$navbar-top-border:                #b3bbc9;
$navbar-top-bg:                    #373D3F;

// Navbar links
$navbar-default-link-color:                #ffffff;
$navbar-default-link-hover-color:          #dddddd;
$navbar-default-link-hover-bg:             transparent;
$navbar-default-link-active-color:         #ffffff;
$navbar-default-link-active-bg:            darken($navbar-default-bg, 6.5%);
$navbar-default-link-disabled-color:       #ccc;
$navbar-default-link-disabled-bg:          transparent;

// Navbar brand label
$navbar-default-brand-color:               $navbar-default-link-color;
$navbar-default-brand-hover-color:         darken($navbar-default-brand-color, 10%);
$navbar-default-brand-hover-bg:            transparent;

// Navbar toggle
$navbar-default-toggle-hover-bg:           #ddd;
$navbar-default-toggle-icon-bar-bg:        #888;
$navbar-default-toggle-border-color:       #ddd;

//=== Shared nav styles
$nav-link-padding:                          10px 15px;
$nav-link-hover-bg:                         #ccc;

$nav-font-size:                             14px;
$nav-font-family:                           inherit;
/*
 * => FONT VARS
 * ---------------------------------------------------------------------------*/
 // Declare your fonts in _fonts.scss

 
 //Footer
 $footer-height:                    75px;
$footer-default-bg:                #373D3F;
$footer-default-border:            darken($navbar-default-bg, 6.5%);
$footer-top-border:                #b3bbc9;
$footer-top-bg:                    #373D3F;

// Footer links
$footer-default-link-color:                #999999;
$footer-default-link-hover-color:          #dddddd;
$footer-default-link-hover-bg:             transparent;
$footer-default-link-active-color:         #ffffff;
$footer-default-link-active-bg:            darken($navbar-default-bg, 6.5%);
$footer-default-link-disabled-color:       #ccc;
$footer-default-link-disabled-bg:          transparent;
 
 

// Mixin for rem font size and fallback
@mixin font-size($sizeValue: 1) {
  font-size: ($sizeValue * $font-size-base);
  font-size: $sizeValue + rem;
}