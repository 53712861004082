// Clearfix
// --------
// For clearing floats like a boss h5bp.com/q
@mixin clearfix {
  *zoom: 1;
  &:before,
  &:after {
    display: table;
    content: "";
    // Fixes Opera/contenteditable bug:
    // http://nicolasgallagher.com/micro-clearfix-hack/#comment-36952
    line-height: 0;
  }
  &:after {
    clear: both;
  }
}
/*
 * => TEXT AND BG CLASSES
 * ---------------------------------------------------------------------------*/
// Text Colors
@mixin text-color($name, $value) {
	.text-#{$name} {
		color: $value;
		p,
		h1, .h1,
		h2, .h2,
		h3, .h3,
		h4, .h4,
		h5, .h5,
		h6, .h6 {
			color: $value;
		}
	}
}


// Background Colors
@mixin bg-color($name, $value) {
	.bg-#{$name} {
		background-color: $value;
	}
}

// build .text-color and .bg-color classes
@each $name, $value in $mainColors {
  @include text-color($name, $value);
  @include bg-color($name, $value);
}

/*
 * => FIXES FOR FIREFOX
 * ---------------------------------------------------------------------------*/

@-moz-document url-prefix() {  
  img.foxfix,
  .foxfix > img {
    width: 100%;
    max-width: 100%;
  }
}

@media screen and (-webkit-min-device-pixel-ratio:0) {    
  img.foxfix,
  .foxfix > img {
    max-width: 100%;
  }    
}




/*
 * => HELPER CLASSES
 * ---------------------------------------------------------------------------*/


// Margins

.margin-top-0    { margin-top: 0; }
.margin-bottom-0 { margin-bottom: 0; }
.man { margin: 0; }

// Quick floats
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}

// Toggling content
.hide {
  display: none;
}
.show {
  display: block;
}
.align-right{text-align: right;}
.align-left{text-align: left;}
.align-none{text-align: none;}
// Visibility
.invisible {
  visibility: hidden;
}

// For Affix plugin
.affix {
  position: fixed;
}

// lines (aka lines under headings)... <div class=line></div>

.line {
	border-bottom: 2px solid #444; 
	max-width: 100%;
	margin: auto;

	&.small {
		width: 500px;
	}

	&.medium {
		width: 950px;
	}

	&.white {
		border-color: #fff;
	}
}

// Vertical Align Elements
// add this to the parent element, and set a specific height for parent element
.valign-middle,
.vertical-middle,
.vertical-center {
	display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

// Bounce things
.bounce {
	animation: bounce 1s 2s infinite ease-in-out;
	position: relative;
}
.bounce-hover:hover {
	animation: bounce 1s 2s infinite ease-in-out;
	position: relative;
}
@keyframes bounce {
	0%   { top: 0; }
	40%  {}
	50%  { top: 10px; }
	55%  { top: 20px; }
	65%  { top: 10px; }
	95%  { top: 0; }
	100% { top: 0; }
}


// Tilt things
.tilt {
  transition: all .2s ease-in-out;
}
.tilt:hover {
  -ms-transform: rotate(7deg); /* IE 9 */
  -webkit-transform: rotate(7deg); /* Chrome, Safari, Opera */
  transform: rotate(7deg);
}


// Grow things
.grow { 
	transition: all .2s ease-in-out; 
}
.grow:hover { 
	-ms-transform: scale(1.1); 
	-webkit-transform: scale(1.1); 
	transform: scale(1.1); 
}

// Hover effect for block elements
.hover-effect:hover {
	box-shadow: 0 0 10px rgba(0,0,0,0.3);
}

@media (max-width: $screen-xs-max) {
    .clear-xs-left {
        clear: left;
    }
    .clear-xs-right {
        clear: right;
    }
    .clear-xs-both {
        clear: both;
    }
    .pull-xs-none {
        float: none !important;
    }
    .pull-xs-left {
        float: left;
    }
    .pull-xs-right {
        float: right;
    }
    .align-xs-right {
        text-align: right;
    }
    .align-xs-left {
        text-align: left;
    }
    .align-xs-center {
        text-align: center;
    }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    .clear-sm-left {
        clear: left;
    }
    .clear-sm-right {
        clear: right;
    }
    .clear-sm-both {
        clear: both;
    }
    .pull-sm-none {
        float: none !important;
    }
    .pull-sm-left {
        float: left;
    }
    .pull-sm-right {
        float: right;
    }
    .align-sm-right {
        text-align: right;
    }
    .align-sm-left {
        text-align: left;
    }
    .align-sm-center {
        text-align: center;
    }
}

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    .clear-md-left {
        clear: left;
    }
    .clear-md-right {
        clear: right;
    }
    .clear-md-both {
        clear: both;
    }
    .pull-md-none {
        float: none !important;
    }
    .pull-md-left {
        float: left;
    }
    .pull-md-right {
        float: right;
    }
    .align-md-right {
        text-align: right;
    }
    .align-md-left {
        text-align: left;
    }
    .align-md-center {
        text-align: center;
    }
}

@media (min-width: $screen-lg-min) {
    .clear-lg-left {
        clear: left;
    }
    .clear-lg-right {
        clear: right;
    }
    .clear-lg-both {
        clear: both;
    }
    .pull-lg-none {
        float: none !important;
    }
    .pull-lg-left {
        float: left;
    }
    .pull-lg-right {
        float: right;
    }
    .align-lg-right {
        text-align: right;
    }
    .align-lg-left {
        text-align: left;
    }
    .align-lg-center {
        text-align: center;
    }
}


