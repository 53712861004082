.footer {
    min-height: 70px;
    border-top: 1px solid $gray;
    background: #373d3f;
    margin-top:100px;
    a {
        color: $footer-default-link-color
    }
    
    .footer_top {
        
        padding-bottom: 26px;
        border-bottom: 1px solid #535353;
        background:$footer-top-bg;
        padding-top: 25px;

        
        .footer-nav {
            height:300px;
            ul {
                margin-bottom: 0;
                > li {
                    list-style-type:none;
                    &.menu-item-has-children {
                        display:inline-block;
                        float:left;
                        width:50%;
                        font-family:$nova-font;
                       

                        ul {
                            margin-left:15px;
                            li {
                                a {
                                    font-size:18px;
                                       @media(max-width:420px) {
                            font-size:12px !important;
                        }
                                }
                            }
                        }
                    }
                    a {
                        text-decoration:none;
                        padding-left:6px;
                        padding-right:6px;
                        color: $footer-default-link-color;
                        font-size: 30px;
                             @media(max-width:420px) {
                            font-size:24px !important;
                        }
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                    + li {
/*                        &:before {
                            content: " | ";
                        }*/
                        display: list-item;
                    }
                    &.current-menu-item {
                        a {
                            color: #fff;
                        }
                    }
                }
            }
        }
        .footer-info {
        }
        
        .footer-social {
            a {
                font-size: 18px;
                display: inline-block;
                margin: 0 6px;
                padding: 2px;

                &:hover {
                    -webkit-animation-duration: 1s; 
                    animation-duration: 1s; 
                    -webkit-animation-fill-mode: both; 
                    animation-fill-mode: both;
                    -webkit-animation-name: pulse; 
                    animation-name: pulse; 
                }
            }
        }
    }
    
    .footer-bottom {
        font-size:10px;
        padding-top: 8px;
        padding-bottom: 8px;
    }
}

.copyright {font-size:12px;color:#ffffff;}