h1,h2,h3 {    
    font-family:$nova-font;
    text-transform: uppercase;
    font-size: 2.5em !important;
    line-height: 1.03703703;
    font-weight: 400;
    text-shadow: 0 1px 1px rgba(255, 255, 255, 0.9);
}
h2 {font-size:46px !important;}
h3 {
    font-size:36px !important;
}


select[id*=country] {
    display:none
}    
label[for*=country] {
    display:none;
}

.home {
    div {
        div {
            main {
                .page-header {display:none;}
            }
        }
    }
}
.page-header {
    border-bottom:none;
}
/*Motopress Custom CSS*/
.motopress-text-obj {
}
.motopress-table-obj {
            margin-top:50px;
            table {
                thead {
                    tr {
                        th {
                            text-align: left;
                            padding-top: 20px !important;
                            font-size: 2em;
                            padding-bottom: 20px !important;
                        }
                    }
                }
            }
}
.comparisontable thead{
    display:table-caption;
    tr > th{
        width:50%;
    }

}
.motopress-list-obj { 
    ul {
        li {
            border-bottom:solid 1px;
            :first-of-type {
                border:none !important;
            }
        }
    }
}
:not(.comparisontable) > thead > tr > th {
    background:#000000 !important;
    color:#FFD200;
    font-family: "proxima-nova-alt-ext-cond", Arial, sans-serif;
    text-shadow: 0 1px 1px rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid #E9E9E9;
    border-radius: 5px 5px 0 0;
    display: block;
    font-size: 42px;
    font-size: 2.1rem;
    font-weight: 200;
    line-height: 1.33333333;
    padding: 14px;
    text-transform: none;
    letter-spacing: normal;
    border-top: 1px solid #CCCCCC;
}
.motopress-cta-obj {margin-top:20px;}


.employee {
    border-bottom:1px solid;
    padding-top:10px;
    padding-bottom:10px;
}

/*hide social*/
.hide-social {
    -webkit-text-security: disc;
}

#ui-datepicker-div {
    /*width:400px;*/
    display:none !important;
}
[scope="col"] {
        display: table-cell;
}

.metaslider .slides img {
    @media(max-width:550px) {
        height:200px;
    }
}
.flexslider .caption-wrap {
    @media(max-width:850px) {
        width:100% !important;
        margin:0 0 0 0 !important;
    }
/*Slider Caption*/
    .caption {
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    line-height:40px;
    font-size:40px;
    @media(max-width:850px) {
        text-align:center;
        font-size:28px !important;
    }
}

}


/*contact form */
#vfb-form-2 {
.vfb-item {
    padding-top: 5px;
    padding-bottom: 5px;
        label {width:100px;float:left;}
        input[type="text"], input[type="email"], .vfb-textarea {width:80%;}
        .vfb-textarea{height:150px;}
}
.vfb-item-secret {
    .vfb-desc {
        width:100%;
    }
    span {
        label {
            width:100%;
        }
    }
}
.vfb-error {
    width:100% !important;
    color:red;
}
}


